import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.scss';
import "./css/custom.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {NotificationContainer} from 'react-notifications';

//const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const loading = () => <div className="loadingArea"><div className="loading"></div></div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

                        <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />                        
                    </Switch>
                    <NotificationContainer/>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;